var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"dataTable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"show-select":_vm.showSelect,"singleSelect":_vm.singleSelect,"item-key":"id","item-class":_vm.lockedRowClass,"expanded":_vm.locked,"search":_vm.performSearch,"footer-props":{'items-per-page-text':'Zeilen pro Seite'}},scopedSlots:_vm._u([{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"buttons-holder"},_vm._l((_vm.actions),function(action){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isButtonDisabled(action, item)),expression:"!isButtonDisabled(action, item)"}],key:action.id,staticClass:"green--text custom-action-icon",class:_vm.isButtonDisabled(action, item) ? 'disabled' : '',attrs:{"src":action.icons},on:{"click":function($event){return _vm.$emit(action.action, item.id, item.name, item.date, item)}}})}),0)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"text-center",attrs:{"colspan":headers.length}},[_vm._v(" Das Inventar wird von "),_c('span',{staticClass:"locked-by"},[_vm._v(_vm._s(item.lockedBy))]),_vm._v(" blockiert. Sie können nur lesen "),_c('v-icon',{staticStyle:{"margin-left":"10px"}},[_vm._v("mdi-lock-outline")])],1)]}},{key:"item.totalTrees",fn:function(ref){
var item = ref.item;
return [(item.totalTrees)?_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"custom-map-area-icon",attrs:{"src":require("../../assets/images/icons/baumliste.svg")}}),_c('span',[_vm._v(_vm._s(item.totalTrees))])]):_vm._e()]}},{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [(!item.isLocked)?_c('div',[_c('v-checkbox',{attrs:{"color":"#1DB954","off-icon":_vm.offIcon,"on-icon":_vm.onIcon,"value":item.id},on:{"click":function($event){return _vm.$emit('selectedRow', item.id)}},model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}})],1):_vm._e()]}}]),model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }