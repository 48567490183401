var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"custom-buttons-holder"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"first-row mb-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.orderedYears,"outlined":"","hide-details":"","menu-props":"auto"},on:{"change":_vm.yearChange},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1),_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('a',{staticClass:"filter"},[_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.getInventarData(_vm.selectedYear)}},model:{value:(_vm.showActualInventory),callback:function ($$v) {_vm.showActualInventory=$$v},expression:"showActualInventory"}}),_c('span',[_vm._v(_vm._s(_vm.showActualInventory ? 'Aktuelle Inventare' : 'Archivierte Inventare'))])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"download-btn",attrs:{"block":"","size":"x-large","height":"54px","color":"#1db954"},on:{"click":function($event){$event.preventDefault();return _vm.goToCreateInventar($event)}}},[_c('v-icon',[_vm._v("mdi mdi-plus-thick")]),_vm._v(" Neues Inventar ")],1)],1)],1)],1)],1)],1)],1),(!_vm.showActualInventory)?_c('v-card',{staticClass:"mt-4 mb-4"},[_c('v-card-text',{staticStyle:{"font-size":"15px !important"}},[_vm._v(" Geschichte des Inventars ")])],1):_vm._e(),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('InventarDatatable',{attrs:{"headers":_vm.headers,"tableData":_vm.tableData,"showSelect":true,"singleSelect":true,"selectedItemsProp":this.inventarId,"locked":_vm.locked,"actions":[
        {
          id: 5,
          action: 'lock',
          icons: require('../../../assets/images/icons/lock.svg'),
          params: '',
          show: false
        },
        {
          id: 6,
          action: 'unlock',
          icons: require('../../../assets/images/icons/unlock.svg'),
          params: '',
          show: false
        },
        {
          id: 1,
          icons: require('../../../assets/images/icons/eye-outline.svg'),
          action: 'view',
          params: '',
          disabled: false
        },
        {
          id: 2,
          icons: require('../../../assets/images/icons/pencil-outline.svg'),
          action: 'edit',
          params: '',
          disabled: true
        },
        {
          id: 3,
          icons: require('../../../assets/images/icons/marker-outline.svg'),
          action: 'map',
          params: '',
          disabled: true
        },
        {
          id: 4,
          icons: require('../../../assets/images/icons/unarchive-tree.svg'),
          action: 'archive',
          params: '',
          show: false
        }
      ],"showActualInventory":_vm.showActualInventory},on:{"edit":_vm.editMethod,"map":_vm.mapMethod,"view":_vm.viewMethod,"archive":_vm.archiveMethod,"lock":_vm.lockMethod,"unlock":_vm.unlockMethod,"selectedRow":_vm.selectedRowMethod}})],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.archiveModalVisible),expression:"archiveModalVisible"}],staticClass:"alert",attrs:{"type":"success","elevation":"20"}},[_vm._v(" Das Inventar wurde erfolgreich wiederhergestellt ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }